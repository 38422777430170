import * as Sentry from '@sentry/browser';
import { EnvType } from '@/services/api/Env';

const initSentry = (env: EnvType) => {
  const IS_PRE_OR_PRD_MODE = env === EnvType.PROD || env === EnvType.PREPROD;
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      release: process.env.VUE_APP_VERSION || '',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment: env,
      profilesSampleRate: IS_PRE_OR_PRD_MODE ? 0.1 : 1,
      tracesSampleRate: IS_PRE_OR_PRD_MODE ? 0.05 : 1,
      replaysSessionSampleRate: IS_PRE_OR_PRD_MODE ? 0.1 : 1,
    });
  }
};

export default initSentry;
